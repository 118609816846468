@use './styles/_mixins.scss' as *;
.footer {
  position: relative;
  padding-top: rem(64);
  padding-bottom: rem(32);
  z-index: 3;
  background: var(--color-background);
  font-size: rem(14);

  @include breakpoint(small) {
    z-index: 3;
  }
  @media (min-width: 1280px) and (max-width: 1379px) {
    padding-bottom: rem(50);
  }
  @media (min-width: 1380px) {
    padding-bottom: 0;
  }

  &_topPadding0 {
    padding-top: 0;
  }

  &_dark {
    background: #101010 url(/assets/noise4.png);
    background-size: 30px !important;
    color: var(--color-white);
  }

  &_top {
    padding-bottom: rem(32);
    margin-bottom: rem(32);
    border-bottom: 1px solid var(--color-grayDark);

    &_heading {
      font-size: rem(28);
      margin-bottom: rem(8);
      text-transform: uppercase;
      font-weight: var(--font-medium);
      @include breakpoint(large) {
        font-size: rem(40);
      }
      @include breakpoint(medium) {
        font-size: rem(33);
      }
    }

    &_subheading {
      font-size: rem(17);
      color: var(--color-primary);
      font-weight: var(--font-medium);
      @include breakpoint(large) {
        font-size: rem(20);
      }
    }
  }

  &_middle {
    @include breakpoint(large) {
      display: flex;
      gap: 32px;
      padding-bottom: 40px;
    }

    &_info {
      @include breakpoint(medium) {
        display: flex;
        flex: 1;
        justify-content: space-between;
      }
      @include breakpoint(large) {
        flex-flow: column;
        & > :nth-child(2) {
          margin-top: rem(24);
        }
      }
    }
  }

  &_column {
    padding-bottom: rem(40);

    @include breakpoint(large) {
      padding-bottom: rem(20);
    }

    &_wrap {
      @include breakpoint(medium) {
        display: flex;
        justify-content: space-between;
        column-gap: 32px;
      }
      @include breakpoint(huge) {
        column-gap: 130px;
      }
    }

    &_social {
      padding-bottom: rem(40);
    }

    &_title {
      margin-bottom: rem(20);
      font-size: rem(20);
      font-weight: var(--font-medium);
      text-transform: uppercase;
    }
    &_list {
      &_item {
        display: flex;
        align-items: center;
        margin-bottom: rem(16);
        transition: color 0.3s ease;

        @include breakpoint(medium) {
          &:hover {
            color: var(--color-primary);
            svg path {
              fill: var(--color-primary);
            }
          }
          svg path {
            transition: fill 0.3s ease;
          }
        }

        svg {
          margin-right: 10px;
        }

        .footer_dark & {
          svg path {
            fill: var(--color-gray3);
          }
        }
      }
    }
  }

  &_nav {
    display: flex;
    flex-flow: column;
    list-style: none;

    @include breakpoint(medium) {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 16px;
    }
    @include breakpoint(huge) {
      column-gap: 32px;
    }

    &_item {
      position: relative;

      &:not(:last-child) {
        margin-bottom: rem(16);
      }

      @include breakpoint(medium) {
        width: fit-content;
      }
    }

    &_link {
      position: relative;
      letter-spacing: -0.5px;

      @include breakpoint(medium) {
        font-size: clamp(0.75rem, 0.7197rem + 0.1294vw, 0.875rem);
      }

      @include breakpoint(large) {
        &:after {
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          bottom: -6px;
          height: 2px;
          background: var(--color-primary);
          transform: scaleX(0);
          transform-origin: left;
          transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
        }

        &:hover {
          &:after {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  &_socials {
    display: flex;
    justify-content: space-between;
    max-width: 315px;

    @include breakpoint(medium) {
      gap: 15px;
    }

    &_item {
      svg {
        width: 30px;
      }
    }

    .footer_dark & {
      &_item {
        &:not(.footer_socials_item_tiktok):not(.footer_socials_item_x):not(
            .footer_socials_item_threads
          ) {
          svg path {
            fill: #fff;
          }
        }
        &_tiktok {
          svg {
            rect {
              fill: #fff;
            }
            path {
              fill: var(--color-dark);
            }
          }
        }
        &_x {
          svg path {
            &:first-child {
              fill: #fff;
              stroke: #fff;
            }
            &:last-child {
              fill: var(--color-dark);
            }
          }
        }
        &_threads {
          circle {
            fill: #fff;
          }
          path {
            fill: var(--color-dark);
          }
        }
      }
    }

    @include breakpoint(medium) {
      &_item {
        svg path,
        svg rect {
          transition:
            fill 0.3s ease,
            stroke 0.3s ease;
        }
        &:not(.footer_socials_item_tiktok):not(
            .footer_socials_item_threads
          ):not(.footer_socials_item_x):hover {
          svg path {
            fill: var(--color-primary);
          }
        }
        &_tiktok:hover {
          svg rect {
            fill: var(--color-primary);
          }
        }
        &_threads:hover {
          svg circle {
            fill: var(--color-primary);
          }
        }
        &_x:hover {
          svg path:first-child {
            fill: var(--color-primary);
            stroke: var(--color-primary);
          }
        }
      }
    }
  }

  &_bottom {
    border-top: 1px solid var(--color-grayDark);
    text-align: center;
    font-size: rem(14);

    @include breakpoint(large) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: rem(16);
      padding-top: rem(8);
    }

    &_nav {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-evenly;
      align-items: center;

      @include breakpoint(medium) {
        justify-content: center;
        gap: 32px;
        margin-bottom: rem(32);
      }

      @include breakpoint(large) {
        margin-bottom: 0;
        order: 1;
        gap: 40px;
      }

      &_item {
        position: relative;

        @include breakpoint(medium) {
          margin-bottom: 0;
        }

        @include breakpoint(large) {
          &:after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: -6px;
            height: 2px;
            background: var(--color-primary);
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
          }

          &:hover {
            &:after {
              transform: scaleX(1);
            }
          }
        }
      }
    }

    @include breakpoint(untilLarge) {
      display: flex;
      flex-direction: column;
      align-items: center;

      ul {
        margin: 1rem 0;
      }

      p {
        margin-bottom: 1rem;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &_copy {
      span {
        display: inline;
        @media (max-width: 500px) {
          display: block;
        }
      }
    }
  }

  &_flag {
    position: relative;
    display: inline-block;
    color: white;
    font-weight: var(--font-medium);
    letter-spacing: -0.5px;

    @include breakpoint(large) {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 370px;
      bottom: 5px;
    }
    @media (min-width: 1380px) {
      bottom: 20px;
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      left: 0;
      width: 60px;
      top: 0;
      bottom: 0;
      background: var(--color-background);
      background: linear-gradient(
        90deg,
        rgba(225, 225, 225, 1) 0%,
        rgba(225, 225, 225, 0) 100%
      );
      z-index: 1;
    }

    &:after {
      right: 0;
      left: auto;
      background: linear-gradient(
        -90deg,
        rgba(225, 225, 225, 1) 0%,
        rgba(225, 225, 225, 0) 100%
      );
    }

    &_dark {
      &:before {
        background: linear-gradient(
          90deg,
          #101010 0%,
          rgba(16, 16, 16, 0) 100%
        );
      }
      &:after {
        background: linear-gradient(
          -90deg,
          #101010 0%,
          rgba(16, 16, 16, 0) 100%
        );
      }

      .footer_flag_wrap {
        &:before {
          background: linear-gradient(
            180deg,
            #101010 0%,
            rgba(16, 16, 16, 0) 100%
          );
        }
        &:after {
          background: linear-gradient(0, #101010 0%, rgba(16, 16, 16, 0) 100%);
        }
      }
    }

    &_wrap {
      display: block;
      position: relative;
      z-index: 1;
      padding: 15px 10px;
      &:before,
      &:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        width: 100%;
        height: 10px;
        top: 0;
        background: var(--color-background);
        background: linear-gradient(
          180deg,
          rgba(225, 225, 225, 1) 0%,
          rgba(225, 225, 225, 0) 100%
        );
        z-index: 1;
      }

      &:after {
        bottom: 0;
        top: auto;
        background: linear-gradient(
          0,
          rgba(225, 225, 225, 1) 0%,
          rgba(225, 225, 225, 0) 100%
        );
      }
    }
  }
}
.footer_inner {
  .footer_top {
    .footer_content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .footer_text {
        flex: 1;
      }

      .footer_image {
        @include breakpoint(small) {
          display: none;
        }
        @include breakpoint(medium) {
          flex: 1;
          position: relative;
          height: 120px;

          img {
            object-fit: contain;
          }
        }
        @include breakpoint(large) {
          height: 200px;
        }
      }
    }
  }
}
