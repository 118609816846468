@use './styles/_mixins.scss' as *;
.navigation {
  @include breakpoint('untilLarge') {
    display: none;
  }
  @include breakpoint('large') {
    &_navOpen {
      visibility: hidden;
    }

    &_list {
      display: grid;
      grid-auto-flow: column;
      gap: 32px;

      :global(.lang-es) & {
        gap: 10px;
      }
    }
    &_item {
      font-weight: var(--font-medium);
      position: relative;
      font-size: clamp(0.76875rem, 0.5563rem + 0.2656vw, 0.875rem);
      text-transform: uppercase;

      :global(.lang-es) & {
        letter-spacing: -0.8px;
      }

      @include breakpoint(large) {
        padding: 8px 0;
      }

      &.navigation_link_disabled span {
        line-height: 1.3;
      }

      &:not(.navigation_link_disabled):after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background: var(--color-primary);
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
      }

      &_active,
      &:hover {
        &:after {
          transform: scaleX(1) !important;
        }
      }

      &:hover {
        & > .navigation_submenu {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &_submenu {
      position: absolute;
      top: 35px;
      left: 0;
      right: 0;
      min-width: 183px;
      padding: 6px 10px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: var(--color-background);
      visibility: hidden;
      opacity: 0;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      transition:
        visibility 0.2s ease,
        opacity 0.2s ease;

      &_link {
        position: relative;
        display: inline-block;
        font-size: 12px;
        padding: 6px 0;
        color: var(--color-secondary);
        letter-spacing: -0.7px;
        transition: transform 0.2s ease;

        &:hover {
          transform: translateX(8px);
        }
      }
    }
  }

  @include breakpoint('huge') {
    &_list {
      :global(.lang-es) & {
        gap: 32px;
      }
    }
    &_item {
      :global(.lang-es) & {
        letter-spacing: 0px;
      }
    }
  }
}
