div.yarl__slide {
  padding: 0;
}
div.yarl__slide_captions_container {
  text-align: center;
}
div.yarl__slide_title {
  font-weight: var(--font-medium);
  white-space: normal;
  font-size: 16px;
  @include breakpoint(large) {
    display: inline-block;
    padding: 16px 24px;
    background-color: rgba(31, 31, 31, 0.92);
    border-radius: 16px;
  }
}
div.yarl__slide_description {
  font-size: rem(14);
  font-weight: var(--font-regular);
  @include breakpoint(large) {
    max-width: 700px;
    font-size: rem(15);
  }
}

div.yarl__thumbnails_container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  background: transparent;
}
button.yarl__thumbnails_thumbnail {
  padding: 0 5px;

  img {
    min-height: 50px;
  }
}
.pdfLightbox .yarl__container {
  background: rgba(0, 0, 0, 0.78);
}
.pdfLightbox .yarl__slide {
  padding-left: 0;
  padding-right: 0;
}

@include breakpoint(small) {
  button.yarl__thumbnails_thumbnail {
    width: 100px;
  }
  .yarl__slide > div,
  .yarl__slide img {
    width: 100% !important;
  }
}
@include breakpoint(untilLarge) {
  .yarl__container object {
    padding-top: 80px;
  }
  .yarl__root > .yarl__container.yarl__flex_center {
    background-color: rgba(31, 31, 31, 0.6);
  }
}
@include breakpoint(large) {
  .pdfLightbox .yarl__container {
    background: rgba(0, 0, 0, 0.1);
  }
  button.yarl__thumbnails_thumbnail {
    transition: transform 0.3s ease-in-out;
    height: 100px;
    &:hover {
      transform: translateY(-10px);
    }
  }
  div.yarl__thumbnails_vignette {
    display: none;
  }
  .yarl__thumbnails_bottom div.yarl__container {
    bottom: 111px;
    background: transparent;
  }
  .yarl__thumbnails_wrapper,
  div.yarl__slide_captions_container {
    background: transparent;
  }
  .yarl__thumbnails_wrapper,
  .yarl__portal {
    background-color: rgba(31, 31, 31, 0.6);
  }
  div.yarl__container {
    background-color: transparent;
  }
}

button.yarl__button {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: var(--color-dark);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: none;
  transition: background 0.2s ease;

  &:hover {
    background: var(--color-gray);
  }
}
button.yarl__navigation_next {
  right: 10px;
}
svg.yarl__icon {
  width: 28px;
  position: relative;
}
button.yarl__navigation_prev {
  left: 10px;
  svg.yarl__icon {
    left: 0;
  }
}
.yarl__slide_image,
.yarl__slide_wrapper video {
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.35);
}

.rpv-core__minimal-button {
  align-items: center;
  display: flex;
}

.rpv-core__page-layer::after,
.modal .rpv-core__page-layer--single:before {
  display: none;
}
