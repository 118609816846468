:root {
  // Colors
  --color-white: #fff;
  --color-background: #e1e1e1;
  --color-primary: #6f6044;
  --color-grayLight: #eeeeee;
  --color-grayDark: #bebbbe;
  --color-gray: #5c5e62;
  --color-gray3: #7d7d7d;
  --color-dark: #171717;
  --color-secondary: #2b2b2b;
  --color-green: #007a4c;

  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-demi: 600;

  --header-height: 68px;
}

body {
  font-family: Termina, sans-serif;
  color: var(--color-secondary);

  &.background-dark {
    color: var(--color-white);
  }
}

em,
i {
  font-style: italic;
}
strong,
b {
  font-weight: var(--font-medium);
}

.bold {
  font-weight: var(--font-medium);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: var(--font-regular);
  line-height: 1.2;
}

p,
a {
  line-height: 1.3;
}

a {
  color: inherit;
  text-decoration: none;
}
*:focus {
  outline: none !important;
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
  height: auto;
  // width: auto;
  display: block;
}
small {
  font-size: 70%;
  line-height: 1.3;
  display: block;
}

.static {
  --bodySize: clamp(0.875rem, 0.8295rem + 0.1942vw, 1.0625rem);
  word-wrap: break-word;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: var(--font-medium);
  }

  h1 {
    margin: 0 0 30px;
    font-size: clamp(1.875rem, 1.6626rem + 0.9061vw, 2.75rem);
  }
  h2 {
    margin: 0 0 24px;
    padding: 12px 0 0;
    font-size: clamp(1.5rem, 1.318rem + 0.7767vw, 2.25rem);
  }
  h3 {
    margin: 0 0 24px;
    padding: 12px 0 0;
    font-size: clamp(1.25rem, 1.159rem + 0.3883vw, 1.625rem);
  }
  h4 {
    margin: 0 0 24px;
    padding: 12px 0 0;
    font-size: clamp(1.0625rem, 1.017rem + 0.1942vw, 1.25rem);
  }
  ul,
  ol {
    padding: 0 0 0 1rem;
    margin: 0 0 2.5rem;
    font-size: var(--bodySize);

    li {
      margin-bottom: rem(16);
    }
  }
  ul {
    list-style-type: disc;
    margin-left: 10px;
  }
  ol {
    counter-reset: item;

    li {
      &::before {
        content: counter(item) '. ';
        counter-increment: item;
        margin-right: 8px;
        display: inline-block;
        font-weight: var(--font-medium);
      }
    }
    // li > strong {
    //   font-size: 18px;
    // }
  }
  p {
    margin-bottom: rem(24);
    font-size: var(--bodySize);

    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: var(--color-primary);
    font-weight: var(--font-medium);
    text-decoration: underline;
  }
  .text-wrap {
    margin-bottom: rem(24);
  }
  img {
    margin: rem(24) auto;
    max-width: 875px;
    // width: 100%;
  }
  br {
    display: block;
    margin: 10px 0;
    content: '';
  }
  video {
    max-width: 100%;
  }
  blockquote {
    background-color: var(--color-white);
    border-radius: 8px;
    font-size: var(--bodySize);
    margin: rem(50) 0;
    padding: rem(40) rem(20) rem(30);
    position: relative;
    @include breakpoint(large) {
      padding: rem(50) rem(30) rem(40) rem(50);
    }

    &:before {
      content: '\201F';
      left: 10px;
      position: absolute;
      top: 0;
      top: 10px;
      font-size: clamp(3.125rem, 2.8975rem + 0.9709vw, 4.0625rem);
      color: var(--color-primary);
      line-height: 1.4;
      @include breakpoint(large) {
        top: 20px;
      }
    }

    p {
      margin-bottom: 0;
      font-size: var(--bodySize);
    }
  }
}

.c-title {
  margin-bottom: rem(32);
  font-size: rem(26);
  text-align: center;
  @include breakpoint(large) {
    margin-bottom: rem(48);
    font-size: rem(36);
  }
}

.darkColor,
p.darkColor {
  color: var(--color-dark);
}
