@use './styles/_mixins.scss' as *;
.langSwitcher {
  position: relative;
  font-weight: var(--font-medium);
  font-size: rem(14);
  text-transform: uppercase;

  @include breakpoint('medium') {
    padding: 12px 0;
  }

  &_flag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:before {
      content: '';
      left: 0;
      top: 0;
      width: 30px;
      height: 20px;
      background: url('/assets/flags_sprite.png');
      margin-right: 8px;
      background-position: -8px -8px;
    }

    &_En:before,
    &_EN:before {
      background-position: -8px -8px;
    }
    &_Es:before,
    &_ES:before {
      background-position: -8px -80px;
    }
    &_Fr:before {
      background-position: -111px -43px;
    }
    &_Ru:before {
      background-position: -8px -44px;
    }
    &_AR:before {
      background-position: -54px -44px;
    }
    &_CN:before {
      background-position: -111px -8px;
    }
    &_JA:before {
      background-position: -54px -80px;
    }
    &_De:before {
      background-position: -62px -8px;
    }
  }

  &:hover {
    .langSwitcher_wrap {
      opacity: 1;
      bottom: 20px;
      visibility: visible;

      @include breakpoint('medium') {
        bottom: auto;
      }
    }
  }

  &_name {
    flex: 1;
    color: #fff;
  }

  &_wrap {
    background-color: rgba(23, 23, 23, 0.8);
    backdrop-filter: blur(10px);
    position: absolute;
    display: flex;
    flex-flow: column;
    bottom: 10px;
    left: -17px;
    padding: 16px;
    min-width: 100px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;

    li:not(:last-child) {
      margin-bottom: rem(16);
    }

    @include breakpoint('medium') {
      top: 35px;
      bottom: auto;

      .langSwitcher_flag {
        transition: transform 0.2s ease;
        &:hover {
          transform: translateX(5px);
        }
      }
    }

    img {
      height: 16px;
    }
  }
}
